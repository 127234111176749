<script setup lang="ts">
interface Props {
    id: string
    modelValue?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue'])

const isChecked = ref(props.modelValue)

watch(() => props.modelValue, () => {
    isChecked.value = props.modelValue
})

const handleChange = (event: Event) => {
    isChecked.value = (event.target as HTMLInputElement).checked;
    emit('update:modelValue', isChecked.value);
}
</script>

<template>
    <label :for="id" class="ui-checkbox">
        <input type="checkbox" :id="id" v-model="isChecked" @change="handleChange" />
        <span class="checkmark"></span>
        <p v-if="$slots.default" class="text-xs">
            <slot />
        </p>
    </label>
</template>

<style scoped lang="scss">
.ui-checkbox {
    @apply inline-block relative pl-6 cursor-pointer;

    input {
        @apply absolute opacity-0 cursor-pointer h-0 w-0;
    }

    .checkmark {
        @apply absolute top-0 left-0 h-4 w-4 bg-gray-50 rounded border border-gray-300 duration-300;

        &:after {
            content: "";
            @apply absolute hidden;
        }
    }
}

.ui-checkbox:hover input~.checkmark {
    @apply bg-gray-200 ;
}

.ui-checkbox input:checked~.checkmark {
    @apply bg-[color:var(--secondary)] border-[color:var(--secondary)];
}


.ui-checkbox input:checked~.checkmark:after {
    @apply block;
}

.ui-checkbox .checkmark:after {
    @apply left-[5px] top-[1px] w-[5px] h-[10px] border border-white border-t-0 border-r-2 border-b-2 border-l-0 rotate-45;
}
</style>